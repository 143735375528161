import { BrowserRouter, Route, Switch } from "react-router-dom";
import GameControl from "./context/GameControl";
import GameScreen from "./GameScreen";
import Notifications from "./Notification";
import ResultScreen from "./ResultScreen";
import WelcomeScreen from "./WelcomeScreen";

/**
 * Sets up the basic structure, defines routes
 */
function App() {
  return (
    <BrowserRouter>
      <GameControl>
        <div className="min-h-screen">
          <Switch>
            <Route exact path="/">
              <WelcomeScreen />
            </Route>
            <Route path="/game">
              <GameScreen />
            </Route>
            <Route path="/result">
              <ResultScreen />
            </Route>
          </Switch>
          <Notifications />
        </div>
      </GameControl>
    </BrowserRouter>
  );
}

export default App;
