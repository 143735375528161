import { motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { useGameControl } from "./context/GameControl";
import { arrayLastElement, randomArrayItem } from "../utils";

/**
 * Gives feedback if the awnser provided by the user is correct or not.
 * Does so via an animated notification with "entertaining" details in its motion style and wording.
 *
 * ## Wording
 * Instead of displaying just _"yes, this is correct"_ or _"no, this is wrong"_ a string out of an
 * extensible list of positive and negative feedback is randomly chosen. Apart from entertaining this helps
 * understanding that a second positive (or second negative) feedback is definitely a new one.
 */
export default function Notifications() {
  const { answeredQuestions } = useGameControl();
  const lastQuestion = arrayLastElement(answeredQuestions);

  return (
    <div
      className={`absolute bottom-20 h-20 overflow-hidden ${
        lastQuestion?.userAnswer === true ? "right-8" : "left-8"
      }`}
    >
      {lastQuestion && (
        <Notification
          isTrue={lastQuestion.correctAnswer === lastQuestion.userAnswer}
          key={lastQuestion.question}
        />
      )}
    </div>
  );
}

type NotificationProps = {
  /** whether to give positive or negative feedback */ isTrue: boolean;
};

/**
 * The Notification itself.
 *
 * Animated by popping up from the bottom with its content popping up in a slightly staggered way.
 */
function Notification(props: NotificationProps) {
  const { isTrue } = props;
  const [isVisible, setIsVisible] = useState(true);

  // hide the Notification after some time again
  useEffect(() => {
    const id = setTimeout(() => setIsVisible(false), 3000);
    return () => clearTimeout(id);
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            when: "beforeChildren",
            staggerChildren: 0.2,
          },
        },
        hidden: {
          opacity: 0,
          y: 100,
          transition: {
            when: "afterChildren",
            staggerChildren: 0.1,
          },
        },
      }}
      className={`overflow-hidden flex items-center shadow-md rounded-md p-3 m-3 text-white font-semibold ${
        isTrue ? "bg-green-500" : "bg-pink-500"
      }`}
    >
      <motion.svg
        variants={itemVariants}
        className={`w-7 h-7 mr-3 ${
          isTrue ? "text-green-200" : "text-pink-200"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d={
            isTrue
              ? "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              : "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          }
        />
      </motion.svg>
      <motion.div variants={itemVariants}>
        {randomArrayItem(isTrue ? positiveFeedback : negativeFeedback)}
      </motion.div>
    </motion.div>
  );
}

const positiveFeedback = [
  "correct!!",
  "well done!",
  "You are right!",
  "Yesssss!",
  "Yep",
];
const negativeFeedback = ["Nope, thats wrong!", "sorry,… but no!", "No…"];

const itemVariants: Variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 40 },
};
