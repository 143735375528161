import {
  DragHandlers,
  motion,
  useMotionValue,
  usePresence,
  useTransform,
} from "framer-motion";
import { ComponentProps, ReactNode, useCallback, useEffect } from "react";
import xss from "xss";
import { useDecisionIndicator } from "./context/DecisionIndicatorGroup";

type GameCardProps = {
  /** content displayed inside the card. Supports text with escaped special characters */
  children: string;
  /** callback to handle the response taken by the user */
  setResponse?: (response: boolean) => void;
  /** current zero based step that is shown in the edges of the card */
  step?: number;
  /** should the card be draggable? Enable this for the user to be able to communicate responses */
  isDraggable?: boolean;
} & Pick<
  ComponentProps<typeof motion.div>,
  "animate" | "transition" | "initial"
>;

/**
 * Displays the current question in a *Card Game* manner.
 * * Features some ornamental elements in the edges that can potentially show the number of current question
 * * can be dragged: *right* means the user assues the question to be true, *left* not
 * * to communicate the current dragging state in a performant manner the used `MotionValue` from `framer motion` can be retried
 * via `setMotionValue`
 * * as the questions in use are using escaped special characters those are wrapped with `xss` to prevent cross site scripting vulnerability
 */
export default function GameCard(props: GameCardProps) {
  const { children, step, setResponse, isDraggable } = props;
  const motionValue = useMotionValue(0);
  const { setMotionValue } = useDecisionIndicator();
  useEffect(() => {
    isDraggable && setMotionValue(motionValue);
  }, [isDraggable, motionValue, setMotionValue]);

  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    !isPresent && safeToRemove?.();
  }, [isPresent, safeToRemove]);

  const scale = useTransform(motionValue, [-150, 0, 150], [0.75, 1, 0.75]);
  const y = useTransform(motionValue, [-150, 0, 150], [100, 0, 100]);
  const rotate = useTransform(motionValue, [-150, 0, 150], [12, 0, -12], {
    clamp: false,
  });

  const handleDragEnd: DragHandlers["onDragEnd"] = useCallback(
    (event, info) => {
      // left side: no
      if (info.offset.x < -5 && info.velocity.x < -100) setResponse?.(false);

      // right side: yes
      if (info.offset.x > 5 && info.velocity.x > 100) setResponse?.(true);
    },
    [setResponse]
  );

  return (
    <motion.div
      className="py-8 px-4 max-w-full md:max-w-xl absolute cursor-move break-words"
      style={
        isDraggable
          ? {
              x: motionValue,
              rotate,
            }
          : undefined
      }
      whileTap={{ cursor: "grabbing" }}
      drag={isDraggable ? "x" : false}
      dragConstraints={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      onDragEnd={handleDragEnd}
      initial={props.initial}
      animate={props.animate}
      transition={props.transition}
      exit={(isTrue) => ({
        x: isTrue ? 500 : -500,
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.2 },
      })}
    >
      <motion.div
        className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl px-8 py-16 relative"
        style={isDraggable ? { scale, y } : undefined}
      >
        <CardOrnament placement="tl">?</CardOrnament>
        <CardOrnament placement="br">?</CardOrnament>
        {step != null && <CardOrnament placement="tr">{step + 1}</CardOrnament>}
        {step != null && <CardOrnament placement="bl">{step + 1}</CardOrnament>}
        <div
          className="font-bold text-3xl leading-normal text-center text-purple-500 text-gradient bg-gradient-to-tl from-purple-600 to-pink-500"
          dangerouslySetInnerHTML={{
            __html: xss(children),
          }}
        />
      </motion.div>
    </motion.div>
  );
}

/**
 * Little decorative elements that be place themselves in the corners of the containing Game Card.
 */
function CardOrnament(props: {
  children: ReactNode;
  /** where to place the Ornament: `tl` = *top left*, `tr` = *top right*, … */
  placement: "tl" | "tr" | "bl" | "br";
}) {
  const { children, placement } = props;
  const rotationMap = {
    tl: -45,
    tr: 45,
    bl: -125,
    br: 125,
  };
  return (
    <div
      role="presentation"
      className="text-pink-200 dark:text-purple-900 text-3xl leading-none font-bold"
      style={{
        position: "absolute",
        transform: `rotate(${rotationMap[placement]}deg)`,
        lineHeight: 1,
        top: placement === "tl" || placement === "tr" ? "1rem" : undefined,
        left: placement === "tl" || placement === "bl" ? "1.5rem" : undefined,
        right: placement === "tr" || placement === "br" ? "1.5rem" : undefined,
        bottom: placement === "bl" || placement === "br" ? "1rem" : undefined,
      }}
    >
      {children}
    </div>
  );
}
