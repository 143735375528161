import { motion, useTransform } from "framer-motion";
import { useDecisionIndicator } from "./context/DecisionIndicatorGroup";

/**
 * renders a colorfull full size element that reflects the current decision tendency via `MotionValue` from `DecisionIndicator` Context.
 * Increasingly greenish background for "yes" increasingly *reddish* background for "no".
 */
export default function YesNoCurtain() {
  const { motionValue } = useDecisionIndicator();
  const bgYesOpacity = useTransform(motionValue, [0, 50], [0, 0.7]);
  const bgNoOpacity = useTransform(motionValue, [0, -50], [0, 0.7]);
  return (
    <>
      <motion.div
        className="bottom-0 right-0 fixed top-0 left-0 bg-gradient-to-tr from-green-500 to-blue-400 dark:from-green-800 dark:to-blue-900"
        style={{ opacity: bgYesOpacity }}
      />
      <motion.div
        className="bottom-0 right-0 fixed top-0 left-0 bg-gradient-to-tl from-red-500 to-yellow-600 dark:from-red-900 dark:to-yellow-900"
        style={{ opacity: bgNoOpacity }}
      />
    </>
  );
}
