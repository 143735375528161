import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

type GameControlProps = {
  children?: ReactNode;
};

/**
 * Logical / functional core of the game app.
 * Comparable to a *Control* as used in MVC inspired patterns.
 * - loads the game model from a service
 * - holds the business logic of the game
 * - provides that logic via an *API* to it's child nodes via react `Context`
 * - wrapps all non-trivial variables (eg upcoming questions) in `useMemo` and all
 * functions in `useCallback` to not break any PureComponent / `memo` optimizations
 */
export default function GameControl(props: GameControlProps) {
  // to keep it simple (and produce nice SSG-snapshot) this will not handle any "pre-loading" state

  const [state, dispatch] = useReducer(reducer, { currentState: "loading" });

  const setAnswer = useCallback(
    (index: number, answer: boolean) =>
      dispatch({ type: "userAnswer", payload: { index, answer } }),
    []
  );

  const currentStep =
    state.currentState === "loaded"
      ? state.quizModel.findIndex((question) => question.userAnswer == null)
      : 0;

  const upcomingQuestions = useMemo(
    () =>
      state.currentState === "loaded" && currentStep >= 0
        ? state.quizModel.slice(currentStep)
        : [],
    [currentStep, state]
  );

  const answeredQuestions = useMemo(
    () =>
      state.currentState === "loaded"
        ? state.quizModel.filter((question) => question.userAnswer != null)
        : [],
    [state]
  );

  const steps = useMemo(
    () => (state.currentState === "loaded" ? state.quizModel.length : 0),
    [state]
  );

  const currentScore = answeredQuestions
    .map((question) => question.userAnswer === question.correctAnswer)
    .filter(Boolean).length;

  const reset = useCallback(() => {
    dispatch({ type: "resetScores" });
  }, []);

  const contextValue = useMemo(
    () => ({
      answeredQuestions,
      currentStep,
      currentState: state.currentState,
      reset,
      setAnswer,
      upcomingQuestions,
      currentScore,
      steps,
    }),
    [
      answeredQuestions,
      currentScore,
      currentStep,
      reset,
      setAnswer,
      state.currentState,
      steps,
      upcomingQuestions,
    ]
  );

  // retrives game data transforms it to the needed form and dispatches it as part of a `modelLoaded` action
  useEffect(() => {
    try {
      navigator.userAgent !== "ReactSnap" &&
        fetch(
          "https://opentdb.com/api.php?amount=10&difficulty=hard&type=boolean"
        )
          .then((e) => e.json())
          .then((e: ServiceResponse) =>
            dispatch({
              type: "modelLoaded",
              result: e.results.map((element) => ({
                title: element.category,
                correctAnswer: element.correct_answer === "True",
                question: element.question,
              })),
            })
          );
    } catch (error) {
      dispatch({ type: "modelLoadFailure", error: error.toString() });
    }
  }, []);

  return (
    <GameContext.Provider value={contextValue}>
      {props.children}
    </GameContext.Provider>
  );
}

type ServiceResponse = {
  results: {
    category: string;
    correct_answer: "True" | "False";
    question: string;
  }[];
};

type QuizModel = {
  title: string;
  userAnswer?: boolean;
  correctAnswer: boolean;
  question: string;
}[];

// to keep things simple there is no "pre-loading" state as there is currently no use case for it.
type State =
  | { currentState: "loading" }
  | { currentState: "loaded"; quizModel: QuizModel }
  | { currentState: "error"; error: string };

type Action =
  | { type: "modelLoaded"; result: QuizModel }
  | { type: "modelLoadFailure"; error: string }
  | { type: "resetScores" }
  | { type: "userAnswer"; payload: { index: number; answer: boolean } };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "modelLoaded":
      return { currentState: "loaded", quizModel: action.result };
    case "modelLoadFailure":
      return { currentState: "error", error: action.error };
    case "resetScores": {
      if (state.currentState === "loaded") {
        const newState = {
          ...state,
          quizModel: state.quizModel.map((entry, index) => ({
            ...entry,
            userAnswer: undefined,
          })),
        };
        return newState;
      }
      return state;
    }
    case "userAnswer": {
      if (
        state.currentState === "loaded" &&
        action.payload.index < state.quizModel.length
      ) {
        const newState = {
          ...state,
          quizModel: state.quizModel.map((entry, index) =>
            index === action.payload.index
              ? { ...entry, userAnswer: action.payload.answer }
              : entry
          ),
        };
        return newState;
      }
      return state;
    }
  }
}

const defaultContext = {
  setAnswer: () => null,
  currentStep: 0,
  currentState: "loading" as const,
  upcomingQuestions: [],
  answeredQuestions: [],
  reset: () => null,
  currentScore: 0,
  steps: 0,
};

const GameContext = createContext<{
  setAnswer: (index: number, answer: boolean) => void;
  currentState: "loaded" | "loading" | "error";
  currentStep: number;
  reset: () => void;
  upcomingQuestions: QuizModel;
  answeredQuestions: QuizModel;
  currentScore: number;
  steps: number;
}>(defaultContext);

export const useGameControl = () => useContext(GameContext);
