import { motion, Variants } from "framer-motion";
import { Link } from "react-router-dom";
import useBrandTitle from "../hooks/useBrandTitle";

/**
 * Initial welcome screen. Introduces used style and motion-design to the user.
 * Content is purely static. Elements are animated upon entering.
 * Welcome tagline is shown initially (and very soon) due to react-snap prerendering.
 */
export default function WelcomeScreen() {
  useBrandTitle("Hi 👋");
  return (
    <motion.div
      variants={parentVariants}
      className="text-center h-screen flex flex-col justify-center text-purple-200 dark:text-purple-300"
      initial="hidden"
      animate="visible"
    >
      <h1 className="mb-12 text-4xl font-thin">
        Welcome to the trivia challenge
      </h1>
      <motion.p className="mb-4" variants={textVariants}>
        you will be presented with
        <span className="text-9xl font-bold opacity-50 block">10</span>
        <span className="font-bold">true</span> or{" "}
        <span className="font-bold">false</span> questions.
      </motion.p>
      <motion.p variants={textVariants} className="font-bold mb-10">
        Can you score <span>100%?</span>
      </motion.p>
      <motion.div variants={ctaVariants}>
        <Link
          to="/game"
          className="uppercase bg-white dark:bg-gray-800 rounded-xl p-4 m-8 shadow-2xl font-bold text-purple-600 flex justify-center items-center"
        >
          <motion.svg
            variants={ctaVariants}
            transition={{ delay: 0.75 }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </motion.svg>
          begin
        </Link>
      </motion.div>
    </motion.div>
  );
}

const parentVariants: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  hidden: {},
};

const textVariants: Variants = {
  visible: navigator.userAgent !== "ReactSnap" ? { opacity: 1, x: 0 } : {},
  hidden: { opacity: 0, x: -100 },
};

const ctaVariants: Variants = {
  visible: navigator.userAgent !== "ReactSnap" ? { opacity: 1, y: 0 } : {},
  hidden: { opacity: 0, y: 100 },
};
