type ProgressProps = {
  currentStep: number;
  availableSteps: number;
};

/**
 * displays the current progress of the game in form of a growing bar.
 */
export default function Progress(props: ProgressProps) {
  const { currentStep, availableSteps } = props;
  return (
    <div className="w-full absolute bottom-4 px-4">
      <div className="bg-purple-500 w-full h-3 rounded-lg overflow-hidden ">
        <div
          className="bg-purple-200 w-full h-full rounded-lg transition-transform"
          style={{
            transform: `scaleX(${currentStep / availableSteps})`,
            transformOrigin: "left",
          }}
        />
      </div>
    </div>
  );
}
