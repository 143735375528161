import { motion, Variants } from "framer-motion";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import xss from "xss";
import { useGameControl } from "./context/GameControl";
import useBrandTitle from "../hooks/useBrandTitle";

/**
 * Screen that is shown upon completing a quiz.
 * retrieves results from the `GameControl` Context.
 * redirects to the initial page if the results are incomplete.
 */
export default function ResultScreen() {
  useBrandTitle("result");
  const {
    answeredQuestions,
    upcomingQuestions,
    reset,
    currentScore,
  } = useGameControl();

  // redirect to the start screen if no result is there. This can happen
  const history = useHistory();
  useEffect(() => {
    if (upcomingQuestions.length > 0) history.push("/");
  }, [history, upcomingQuestions.length]);

  return answeredQuestions ? (
    <div className="grid gap-2 p-4">
      <ScoreDisplay
        scored={currentScore || 0}
        possible={answeredQuestions?.length || 0}
      />
      <motion.ul
        initial="hidden"
        animate="visible"
        variants={listVariants}
        className="grid gap-2"
      >
        {answeredQuestions?.map((question, index) => {
          const wasAnsweredCorrectly =
            question.userAnswer === question.correctAnswer;
          return (
            <ResponseSummary
              answer={question.correctAnswer}
              wasAnsweredCorrectly={wasAnsweredCorrectly}
              index={index}
              question={question.question}
              key={question.question}
            />
          );
        })}
      </motion.ul>
      <div
        onClick={reset}
        className="uppercase bg-white rounded-xl p-4 mt-4 shadow-xl hover:shadow-2xl text-purple-600 font-bold flex justify-center items-center dark:bg-gray-800"
      >
        <svg
          className="w-5 h-5 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        play again
      </div>
    </div>
  ) : (
    <>loading</>
  );
}

type ScoreDisplayProps = {
  /**amount of right awnsers */
  scored: number;
  /**total amount of question */
  possible: number;
};
/**
 * Prominent "hero" like summary of the Score.
 */
function ScoreDisplay(props: ScoreDisplayProps) {
  const { scored, possible } = props;

  return (
    <motion.div
      variants={scoreVariants}
      initial="hidden"
      animate="visible"
      className="mb-6 mt-8 rounded-2xl bg-white px-8 py-16 shadow-xl dark:bg-gray-800"
    >
      <div className="uppercase flex items-center text-gradient bg-gradient-to-tr from-purple-600 to-pink-500 justify-end">
        <div className="text-right mr-2">
          you
          <br /> scored
        </div>
        <div className="text-6xl tracking-tighter">
          <span className="font-black">{scored}</span>{" "}
          <span className="font-thin">/ {possible}</span>
        </div>
      </div>
    </motion.div>
  );
}

type ResponseSummaryProps = {
  question: string;
  index: number;
  answer: boolean;
  wasAnsweredCorrectly: boolean;
};
/**
 * Single entry in the response summary list representing a question, it's index, its correct awnser
 * and an icon indicating if the user answered correctly.
 */
function ResponseSummary(props: ResponseSummaryProps) {
  const { index, wasAnsweredCorrectly, question, answer } = props;

  return (
    <motion.li
      variants={itemVariants}
      className="p-4 rounded-2xl bg-white dark:bg-gray-800 flex"
    >
      <div className="mr-3 text-purple-100 text-5xl dark:text-purple-900">
        {index + 1}
      </div>
      <div>
        <span
          className="text-purple-600 "
          style={{ hyphens: "auto" }}
          dangerouslySetInnerHTML={{ __html: xss(question) }}
        />
        <span className="uppercase text-xs bg-purple-50 text-purple-400 dark:bg-purple-900 px-2 ml-1 rounded-2xl">
          {answer === true ? "true" : "false"}
        </span>
        <svg
          className={`w-4 h-4 inline-block ${
            wasAnsweredCorrectly ? "text-green-300" : "text-red-300"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={
              wasAnsweredCorrectly
                ? "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                : "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            }
          />
        </svg>
      </div>
    </motion.li>
  );
}

const listVariants: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 2,
    },
  },
  hidden: {},
};

const scoreVariants: Variants = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.1,
    },
  },
  hidden: { opacity: 0, scale: 0.5, y: -200 },
};

const itemVariants: Variants = {
  visible: {
    opacity: 1,
    x: 0,
  },
  hidden: { opacity: 0, x: -100 },
};
