import { motion, useTransform } from "framer-motion";
import { useDecisionIndicator } from "./context/DecisionIndicatorGroup";

/**
 * renders the explicit yes and no controls at the bottom of the page.
 * Can use the shared MotionValue from a parent `DecisionIndicatorGroup` to
 * animate the size of the button that reflects the current decision tendency.
 */
export default function YesNoButtons(props: {
  /**handles the click on yes or no button */
  onResponseClicked: (response: boolean) => void;
}) {
  const { onResponseClicked: setCurrentResponse } = props;
  const { motionValue } = useDecisionIndicator();

  const scaleYes = useTransform(motionValue, [0, 300], [1, 2]);
  const scaleNo = useTransform(motionValue, [0, -300], [1, 2]);
  const opacityArrows = useTransform(motionValue, [-300, 0, 300], [0, 1, 0]);
  return (
    <div className="absolute bottom-12 grid grid-flow-col w-full text-xl text-pink-100 px-4 ">
      <button
        className="uppercase font-bold flex items-center focus:outline-none focus:bg-purple-900 focus:bg-opacity-40 rounded-lg text-purple-200 dark:text-purple-500"
        onClick={() => setCurrentResponse(false)}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
          style={{ opacity: opacityArrows }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
          />
        </motion.svg>
        <motion.div style={{ scale: scaleNo }}>no</motion.div>
      </button>
      <button
        className="py-2 uppercase font-bold flex items-center justify-end focus:outline-none focus:bg-pink-900 focus:bg-opacity-40 rounded-lg text-pink-200 dark:text-pink-500"
        onClick={() => setCurrentResponse(true)}
      >
        <motion.div style={{ scale: scaleYes }}>yes</motion.div>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 ml-2 justify-end"
          style={{ opacity: opacityArrows }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </motion.svg>
      </button>
    </div>
  );
}
