import { motionValue } from "framer-motion";
import { ReactNode, useMemo } from "react";
import { createStateContext } from "react-use";

/**
 * Keeps decision indication animations of child components in sync.
 *
 */
export default function DecisionIndicatorGroup(props: { children: ReactNode }) {
  return (
    <DecisionIndicatorProvider>{props.children}</DecisionIndicatorProvider>
  );
}

export function useDecisionIndicator() {
  const [motionValue, setMotionValue] = useDecisionIndicatorRaw();
  return useMemo(() => ({ motionValue, setMotionValue }), [
    motionValue,
    setMotionValue,
  ]);
}

const [useDecisionIndicatorRaw, DecisionIndicatorProvider] = createStateContext(
  motionValue(0)
);
