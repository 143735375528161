import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useBrandTitle from "../hooks/useBrandTitle";
import { arrayLastElement } from "../utils";
import DecisionIndicatorGroup from "./context/DecisionIndicatorGroup";
import { useGameControl } from "./context/GameControl";
import GameCard from "./GameCard";
import Progress from "./Progress";
import YesNoButtons from "./YesNoButtons";
import YesNoCurtain from "./YesNoCurtain";

/**
 * Handles the interaction / UI logic.
 * * Retrieves game logic from the `GameControl` Context.
 * * Save user responses to the `GameControl` Context
 * * Orchestrates game card-, background- & button-animations via `DecisionIndicatorGroup`.
 * * renders fallback screens when data is not loaded yet or an error occured.
 *
 * The game contains cards with questions that can be dragged to the right if the user thinks that the response is "yes"
 * or to the left side otherwise. The current decision tendency is reflected by a growing *Yes* Button at the bottom and an
 * increasingly greenish background or a growing *No* Button with an increasingly *reddish* background.
 * Flicking the card to either direction confirms that tendency. As a redundant and more explicit / accessible way the
 * *yes* and *no* buttons can be pushed. Behind the current question card the next card starts to get visible if existing.
 *
 * ## Drag Animations
 * The dragging animation logic itself is handled inside the `GameCard` Component.
 * To orchestrate it's current dragging position with the other elements in a performant way the internally used `MotionValue`
 * from Framer Motion is shared from the Card via the `DecisionIndicatorGroup` Component.
 * This works comparable to an animation oriented communication bus system that bypasses react render.
 * The `YesNoButtons` and `YesNoCurtain` components use that shared MotionValue to animate their proper styles
 *
 * ## Enter-Exit animations
 * To keep the component fast and responsive only the currently shown Game Cards are added to the React and DOM tree.
 * To be able to create enter and exit animations upon answering single questions framer-motions `AnimatePresence` is used.
 * To calculate the right direction of the exit animation the `userAnswer` of the last question is passed to AnimatePresence's
 * `custom` prop.
 *
 * # Fallback screens
 * When the data is not loaded yet a simple "loading" information is shown together with the "yes" & "no" buttons.
 * This is especially helpfull as it will also be the result of the prerendered pages before hydration.
 * So the user can start orienting and understanding the page structure immediately.
 *
 */
export default function GameScreen() {
  useBrandTitle("questions");
  const {
    setAnswer,
    steps,
    currentStep,
    upcomingQuestions,
    answeredQuestions,
    currentState,
  } = useGameControl();

  const setCurrentResponse = useCallback(
    (response: boolean) => setAnswer(currentStep, response),
    [setAnswer, currentStep]
  );

  const lastQuestion = arrayLastElement(answeredQuestions);
  const currentQuestion = upcomingQuestions[0];
  const nextQuestion = upcomingQuestions[1];

  // redirect to the result page once there are no more upcoming questions
  const history = useHistory();
  useEffect(() => {
    if (currentState === "loaded" && upcomingQuestions.length === 0)
      history.push("/result");
  }, [currentState, history, upcomingQuestions.length]);

  return (
    <DecisionIndicatorGroup>
      <YesNoCurtain />
      {currentState === "loading" ? (
        <Message>loading game…</Message>
      ) : currentState === "error" ? (
        <Message>sorry, an error occured. please reload</Message>
      ) : (
        <>
          <h1 className="text-center m-4 uppercase font-bold text-pink-900 dark:text-pink-500">
            {currentQuestion?.title}
          </h1>
          <div className="w-full relative md:max-w-xl m-auto">
            <AnimatePresence custom={lastQuestion?.userAnswer}>
              {nextQuestion && (
                <GameCard
                  step={currentStep + 1}
                  key={currentStep + 1}
                  initial={{
                    scale: 0,
                    y: -200,
                    opacity: 0,
                  }}
                  animate={{
                    scale: 0.75,
                    y: -60,
                    opacity: 0.25,
                  }}
                  transition={{
                    scale: { duration: 0.2 },
                    opacity: { duration: 0.4 },
                  }}
                >
                  {nextQuestion.question}
                </GameCard>
              )}
              {currentQuestion && (
                <GameCard
                  isDraggable
                  step={currentStep}
                  key={currentStep}
                  animate={{
                    scale: 1,
                    y: 0,
                    opacity: 1,
                  }}
                  transition={{
                    stiffness: 300,
                    damping: 20,
                    opacity: {
                      duration: 0.2,
                    },
                  }}
                  setResponse={setCurrentResponse}
                >
                  {currentQuestion.question}
                </GameCard>
              )}
            </AnimatePresence>
          </div>
        </>
      )}

      <YesNoButtons onResponseClicked={setCurrentResponse} />
      <Progress currentStep={currentStep} availableSteps={steps} />
    </DecisionIndicatorGroup>
  );
}

/**
 * displays rather unexpected feedback instead of the "real" content
 * such as loading states or unexpected errors
 */
function Message(props: { children: string }) {
  return (
    <div className="text-center uppercase my-16 text-xl text-pink-300">
      {props.children}
    </div>
  );
}
