import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";

const rootElement = document.getElementById("root");
// hydrate with "prerendered" html content created via `react-snap`
if (rootElement?.hasChildNodes()) {
  hydrate(
    <StrictMode>
      <App />
    </StrictMode>,
    rootElement
  );
} else {
  render(
    <StrictMode>
      <App />
    </StrictMode>,
    rootElement
  );
}

reportWebVitals();
